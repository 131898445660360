import { IUploadFile } from "./UploadFile"

export interface IDocument {
  privacyPolicyFile?: IUploadFile
  termsAndConditionsCustomerFile?: IUploadFile
  termsAndConditionsBusinessFile?: IUploadFile
  venderBrochureFile?: IUploadFile
}

export const documentInitialValue: IDocument = {
  privacyPolicyFile: null,
  termsAndConditionsCustomerFile: null,
  termsAndConditionsBusinessFile: null,
  venderBrochureFile: null,
}
