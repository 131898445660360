import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { IDocument } from 'src/types/Document'
import { api } from '.'

type IDocumentApi = {
  update(document: IDocument): Promise<{ success: boolean }>
}

const DocumentApi = (): IDocumentApi => {
  const documentApi = '/admin/settings/documents'

  const update = (document: IDocument): Promise<{ success: boolean }> => api.post(documentApi, generateMultipartBodyForm(document))

  return { update }
}

export default DocumentApi()
