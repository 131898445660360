import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import FileUpload from 'src/components/Form/FileUpload'
import { FormCard } from 'src/components/Layout/FormCard'
import { IDocument } from 'src/types/Document'
import { Formik } from 'src/types/Formik'

const DocumentForm: React.FC<{ formik: Formik<IDocument> }> = ({ formik }) => {
  const { setFieldValue } = formik

  const onUploadFile = useCallback(
    (name: string, file: File) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  return (
    <FormCard title="Documents">
      <SimpleGrid columns={1} spacing={4} >
        <GridItem display="flex" alignItems="flex-start">
          <FileUpload
            onUpload={onUploadFile}
            name="privacyPolicyFile"
            label="Privacy Policy (PDF)"
          />
        </GridItem>
        <GridItem display="flex" alignItems="flex-start">
          <FileUpload
            onUpload={onUploadFile}
            name="termsAndConditionsCustomerFile"
            label="Terms and Conditions Customer (PDF)"
          />
        </GridItem>
        <GridItem display="flex" alignItems="flex-start">
          <FileUpload
            onUpload={onUploadFile}
            name="termsAndConditionsBusinessFile"
            label="Terms and Conditions Business (PDF)"
          />
        </GridItem>
        <GridItem display="flex" alignItems="flex-start">
          <FileUpload
            onUpload={onUploadFile}
            name="venderBrochureFile"
            label="Vendor Brochure (PDF)"
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default DocumentForm
