import SurveyAnswerTypeEnum from "src/enum/SurveyAnswerTypeEnum"
import SurveyTriggerEnum from "src/enum/SurveyTriggerEnum"

export interface ISurvey {
  id?: string
  title: string
  question: string
  storeIdList: string[]
  trigger: SurveyTriggerEnum
  answerType: SurveyAnswerTypeEnum
  options: ISurveyOption[]
  isActive?: boolean
}

export type ISurveyOption = {
  label: string
  value: string
}

export const surveyInitialValue: ISurvey = {
  title: '',
  question: '',
  storeIdList: [],
  trigger: SurveyTriggerEnum.NEXT_TIME_OPENING_APP,
  answerType: SurveyAnswerTypeEnum.SINGLE_OPTION,
  options: [],
}
