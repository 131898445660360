import { IAddress, IGoogleAddressResponse } from './Address'
import { ICharity } from './Charity'
import { IPayment } from './Payment'
import { ISocialMedia } from './SocialMedia'

export interface IUser {
  _id?: string
  id: string
  firstName: string
  lastName: string
  googleAddress?: string
  phoneNumber: string
  email: string
  loginPhoneNumber?: string
  referralCode: string
  creditBalance: number
  avatar?: string
  stripeCustomerId?: string
  isCharity?: boolean
  charityId?: string

  approved?: boolean
  active?: boolean
  stores?: IStore[]
  referrer?: IUser

  invitedUsers?: IUser[]

  address?: IAddress

  placeId?: string
  createdAt?: Date
}

export interface IStore {
  _id?: string
  businessName: string
  legalBusinessName: string
  contactName: string
  contactEmail: string
  businessPhoneNumber: string
  abn: string
  businessDescription: string
  businessType: string
  active?: boolean
  isVisible: boolean
  businessLogoFile?: File
  businessCoverFile?: File
  documentIdentityFile?: File
  businessLogo?: string
  businessCover?: string
  businessVideo?: string
  address: IGoogleAddressResponse | IAddress
  bsb: string
  accountNumber: string
  commissionPercentage: number
  zeroCommissionTrialEndsAt?: Date | string | null
  statementEmail: string
  pickupTime: {
    from: Date
    to: Date
  }
  favoritesQty?: number
  reviewsLength?: number
  shortUrl?: string
  socialMedias?: ISocialMedia[]

  charity?: ICharity

  payments?: IPayment[]
  isInCommissionTrial?: boolean

  [key: string]: any
}

export interface IStorePaymentGroup {
  businessOwnerId: string
  totalToBePaid: number
  ordersCount: number
  stores: IStore[]
}

export interface ICustomerFilter {
  name?: string
  familyName?: string
  phoneNumber?: string
  email?: string
}

export interface IStoreFilter {
  code?: string
  businessName?: string
  contactName?: string
  pendingApproval?: boolean
}

export const userInitialValue: IUser = {
  id: '',
  firstName: '',
  lastName: '',
  googleAddress: '',
  phoneNumber: '',
  email: '',
  creditBalance: 0,
  referralCode: '',
}

export const storeInitialValue: IStore = {
  businessName: '',
  legalBusinessName: '',
  contactName: '',
  contactEmail: '',
  businessPhoneNumber: '',
  abn: '',
  businessDescription: '',
  businessType: '',
  address: {
    description: '',
    placeId: '',
  },
  bsb: '',
  accountNumber: '',
  isVisible: false,
  addressTemp: '',
  commissionPercentage: 0,
  statementEmail: '',
  zeroCommissionTrialEndsAt: null,
  pickupTime: {
    from: new Date(),
    to: new Date(),
  },
}

export const storeFilterInitialValue: IStoreFilter = {
  code: '',
  businessName: '',
  contactName: '',
  pendingApproval: undefined,
}

export const bankAccountInitialValue: IBankAccount = {
  bsb: '',
  accountNumber: '',
}

export interface IBankAccount {
  bsb: string
  accountNumber: string
}

export const userFilterInitialValue: ICustomerFilter = {
  name: '',
  familyName: '',
  phoneNumber: '',
  email: '',
}

export default IUser

export interface IBusinessOwnerFilter {
  state?: string
}


export const businessOwnerFilterInitialValue: IBusinessOwnerFilter = {
  state: ''
}