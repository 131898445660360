import { Box, Center, Flex, Link, Text } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { FormCard } from './FormCard'

interface StoreAmbassadorCardProps {
  store: IStore
}

export function StoreAmbassadorCard({ store }: StoreAmbassadorCardProps) {
  return (
    <FormCard title="Store Ambassador">
      <Center flexDirection="column">
        <Box borderRadius="15px" overflow={'hidden'} width="fit-content">
        </Box>
        <Flex justifyContent="flex-start" w="full">
          <Link href={`/app/customers/${store.ambassador?._id}`} color="primary.500">
            <Text>
              {store.ambassador?.firstName ?? '-'} {store.ambassador?.lastName ?? ''}
            </Text>
          </Link>
        </Flex>
      </Center>
    </FormCard>
  )
}
