import { Box, Center, Flex, Link, Text } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { FormCard } from './FormCard'

interface StorePartnerCardProps {
  store: IStore
}

export function StorePartnerCard({ store }: StorePartnerCardProps) {
  return (
    <FormCard title="Partner">
      <Center flexDirection="column">
        <Box borderRadius="15px" overflow={'hidden'} width="fit-content">
        </Box>
        <Flex justifyContent="flex-start" w="full">
          <Link href={`/app/partners/${store.partner?._id}`} color="primary.500">
            <Text>
              {store.partner?.firstName ?? '-'} {store.partner?.lastName ?? ''}
            </Text>
          </Link>
        </Flex>
      </Center>
    </FormCard>
  )
}
