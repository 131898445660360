import { Box } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import DocumentApi from 'src/api/document'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IDocument, documentInitialValue } from 'src/types/Document'
import DocumentForm from './DocumentForm'
import validationSchema from './validationSchema'

const Document = () => {
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IDocument) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    let data = {}

    if (values.privacyPolicyFile) data = { ...data, privacyPolicyFile: values.privacyPolicyFile }
    if (values.termsAndConditionsCustomerFile) data = { ...data, termsAndConditionsCustomerFile: values.termsAndConditionsCustomerFile }
    if (values.termsAndConditionsBusinessFile) data = { ...data, termsAndConditionsBusinessFile: values.termsAndConditionsBusinessFile }
    if (values.venderBrochureFile) data = { ...data, venderBrochureFile: values.venderBrochureFile }

    if (Object.keys(data).length === 0) {
      dispatch({ type: Types.Loading, payload: { loading: false } })
      return
    }

    DocumentApi.update(data)
      .then(() => {
        AlertSuccess('Documents uploaded successfully!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: documentInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title="Document">
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title="Document" buttons={
          <PrimaryButton type="submit">Save</PrimaryButton>
        } />
        <Box mt={3}>
          <DocumentForm formik={formik} />
        </Box>
      </form>
    </Page>
  )
}

export default Document
